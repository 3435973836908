html, body {
  height: 100%;
}

a:hover {
  cursor: pointer;
}

.auth-switcher-link {
  color: #0056b3 !important;
  text-decoration: underline !important;;
}


.content-wrapper {
  margin: 0rem 1rem;
}

.pointer:hover {
  cursor: pointer;
}

/*ngx-datatable*/
.ngx-datatable.bootstrap .datatable-footer {
  background: #7a9200;
}

.sort-active .datatable-header-cell-label {
  color: #a2c100;
}

.ngx-datatable .datatable-header .datatable-header-cell.resizeable .resize-handle{
  visibility: visible!important;
}

/*change default sort icons @swimlane/ngx-datatable/release/assets/icons-reference.html*/
.ngx-datatable .sortable .sort-btn:before {
  font-family: data-table;
  content: "c";
}

.ngx-datatable .sortable .sort-btn.datatable-icon-down:before {
  content: "f";
}

.ngx-datatable .sortable .sort-btn.datatable-icon-up:before {
  content: "e";
}


.datatable-header-cell-label:hover {
  color: #a2c100;
  text-decoration: underline;
}

.datatable-row-detail {
  background: #ffffff !important;
}

.datatable-checkbox input[type='checkbox']:before {
  border: 1px solid #292626;
}

.datatable-checkbox input[type='checkbox']:after {
  border: 1px solid #292626;
}

/*buttons*/

.btn-default {
  background-color: white;
  border-color: #e0e0e0;
}

.btn-outline-success {
  color: white;
  background: #a2c100;
  border-color: #a2c100;
}

.btn-outline-success:hover, .btn-outline-success:active, .btn-outline-success:focus, .btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #a2c100;
  border-color: #a2c100;
}

.btn-outline-success:disabled {
  background: #a2c100;
  color: white;
  cursor: not-allowed;
}

.btn-outline-success:active {
  color: #fff !important;
  background-color: #a2c100 !important;
  border-color: #a2c100 !important;
}

.btn-outline-success:focus {
  box-shadow: none;
}

.btn:disabled {
  cursor: not-allowed;
}

.disabled:hover {
  cursor: not-allowed;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a2c100;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(162, 193, 0, 0.32);
}

.ng-value {
  background-color: #fcffea36 !important;
  border: 1px solid #a2c100 !important;
}

/*datepicker color*/
.theme-green .bs-datepicker-head {
  background-color: #a2c100;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #a2c100;
}

/*slider*/
.noUi-handle {
  border: 1px solid #a2c100;
  border-radius: 3px;
  background: #a2c100;
  cursor: default;
  -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  box-shadow: inset 0 0 1px #a2c100, inset 0 1px 7px #a2c100, 0 3px 6px -3px #a2c100;
}

/*mobile elements*/
.listview-subtitle {
  display: block;
}

.listview-item-chavron-wrapper {
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.listview-item-download-button {
  margin-left: 15px;
}

/*Dropdowns*/

.dropdown-toggle::after {
  float: right;
  margin-top: 0.5rem;
}

/* Modals */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 920px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1120px;
    margin: 1.75rem auto;
  }
}
